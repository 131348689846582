import React from 'react';
import classnames from 'classnames';

import style from './Content.sass';

export interface Props {
  center?: boolean;
  className?: string;
}

const Content: React.FC<Props> = ({ center, className, ...rest }) => (
  <div {...rest} className={classnames(style.root, { [style.center]: center }, className)} />
);

export default Content;
