/* eslint-disable @typescript-eslint/camelcase */

import React from 'react';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';

import { urls } from '@/constants';
import { getYearsOfExperience } from '@/constants/experience';

export interface Props {
  title?: string;
  description?: string;
  images?: string[];
  type?: 'article';
  noindex?: boolean;
}

const Seo: React.FC<Props> = ({ title, description, images, type, noindex }) => {
  title = title ? `${title} - Botond Veress` : `Botond Veress - Passionate developer & solution architect`;

  description =
    description ||
    `I am full-stack developer & solution architect with ${getYearsOfExperience()}+ years of experience in web development, architecture, and cloud computing.`;

  images = images && images.length > 0 ? images : [require('@/assets/share.jpg')];

  const router = useRouter();

  const url = urls.getAbsoluteUrl(router.asPath);

  return (
    <NextSeo
      title={title}
      description={description}
      canonical={url}
      openGraph={{
        type: type || 'website',
        url,
        title,
        site_name: 'Botond Veress',
        description,
        images: images
          .filter((image) => image)
          .map((image) => ({ url: image.indexOf('/') === 0 ? urls.getAbsoluteUrl(image) : image }))
      }}
      noindex={noindex}
    />
  );
};

export default Seo;
